import {DarkModeStore} from '@store/DarkmodeStore'
import {useState} from 'react'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

const useDark = () => {
    const {isDark} = DarkModeStore()
    const [isRenderDark, setIsRenderDark] = useState(false)

    useIsomorphicLayoutEffect(() => {
        setIsRenderDark(isDark)
    }, [isDark])

    return {isRenderDark}
}

export default useDark
