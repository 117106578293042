import {useEffect, useState} from 'react'

export const useVisibilityChange = () => {
    const [visibility, setVisibility] = useState<DocumentVisibilityState>()
    useEffect(() => {
        const handleVisibilityChange = () => {
            setVisibility(document.visibilityState) // visible, hidden, prerender, unloaded
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [])

    return visibility
}
