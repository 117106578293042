import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetDailyReport, IDailyReport} from '@api/user/report/getDailyReport'
import {useRouter} from 'next/router'
import {useLoginStore} from '@store/LoginStore'
import {isNotEmpty} from '@util/strings'

const useQueryGetTodayDailyReport = (enabled: boolean = true) => {
    const router = useRouter()
    const userId = useLoginStore(state => state.id)
    const {data, refetch} = useQuery({
        queryKey: QueryCacheKeys.report.getTodayDailyReport(userId, router?.locale),
        enabled: enabled && isNotEmpty(userId),
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const {data} = await apiGetDailyReport('')
            if (data) return data
            return {} as IDailyReport
        },
    })
    return {data, refresh: refetch}
}

export default useQueryGetTodayDailyReport
