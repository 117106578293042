import {useEffect, useRef, useState} from 'react'

enum TimerState {
    Waiting,
    Stop,
    Running,
}

interface IPropsTimer {
    onStarted?: () => void
    onEnded?: () => void
}

const useTimer = (props: IPropsTimer) => {
    const {onStarted, onEnded} = props
    const [initialTime, setInitialTime] = useState<number>(0)
    const [timeLeft, setTimeLeft] = useState<number>(initialTime)
    const [timerState, setTimerState] = useState<TimerState>(TimerState.Waiting)
    const timer = useRef<any>()
    const startTimer = (replaceLeftTime: number = 0) => {
        if (timerState === TimerState.Running) {
            setTimerState(TimerState.Waiting)
        }

        setInitialTime(replaceLeftTime)
        setTimeLeft(replaceLeftTime)

        setTimerState(TimerState.Running)
    }

    const stopTimer = () => {
        setTimeLeft(0)
        setTimerState(TimerState.Stop)
    }

    useEffect(() => {
        clearInterval(timer.current)

        // todo Refactoring 필요, 상태값을 STOP, Running만 유지하고 Waiting은 삭제하는 방식으로
        if (timerState === TimerState.Running) {
            if (timeLeft > 0) {
                setTimeLeft(initialTime)
                onStarted && onStarted()

                timer.current = setInterval(() => {
                    setTimeLeft(timeLeft => {
                        if (timeLeft - 1 <= 0) {
                            clearTimeout(timer.current)
                            setTimerState(TimerState.Stop)
                            return 0
                        } else {
                            return timeLeft - 1
                        }
                    })
                }, 1000)
            }
        } else if (timerState === TimerState.Stop) {
            clearTimeout(timer.current)
            onEnded && onEnded()
        } else {
            clearTimeout(timer.current)
        }

        return () => {
            clearInterval(timer.current)
            setTimeLeft(0)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerState])

    return {timeLeft, startTimer, stopTimer}
}

export default useTimer
