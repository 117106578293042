import {useLocalStorage} from 'usehooks-ts'
import {IS_LOGIN_FAILED_STATE_KEY} from '@constant/key'

export default function useLoginCallback() {
    const [isLoginFailed, setIsLoginFailed] = useLocalStorage(IS_LOGIN_FAILED_STATE_KEY, false)

    const removeStorage = () => {
        localStorage.removeItem(IS_LOGIN_FAILED_STATE_KEY)
    }

    return {isLoginFailed, setIsLoginFailed, removeStorage}
}
