import {useQuery} from '@tanstack/react-query'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetPendingInvoices} from '@api/user/account/invoice/getPendingInvoices'
import {useLoginStore} from '@store/LoginStore'
import {IInvoice} from '@type/invoice'
import {isNotEmpty} from '@util/strings'

const useQueryGetPendingInvoices = (enabled: boolean = true) => {
    const loginUserId = useLoginStore(state => state.id)
    const router = useRouter()
    const {data, refetch, isLoading} = useQuery({
        queryKey: QueryCacheKeys.invoice.getPendingInvoiceList(loginUserId),
        enabled: enabled && isNotEmpty(loginUserId),
        staleTime: 1000 * 10,
        cacheTime: 1000 * 10,
        queryFn: async () => {
            const {data} = await apiGetPendingInvoices()
            if (data) return data
            return [] as IInvoice[]
        },
        refetchOnWindowFocus: false,
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])

    return {data, refresh: refetch, isLoading}
}

export default useQueryGetPendingInvoices
