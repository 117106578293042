import {DayDuration} from '@type/time'
import {
    RankKind,
    RankPeriod,
    RankSortBy,
} from '@feature/leaderboard/pages/ScreenMain/MainLeaderboardHeaders/MainLeaderboardOptionContainer'
import {AnnounceType} from '@api/announce/getAnnouncements'
import {BotType} from '@api/bot/bot'
import {HistorySortType} from '@api/key/portfolio/getHistory'
import {GraphPeriodOptionType} from '@hook/useChartPeriod'
import {LeaderPnlPeriodType} from '@api/key/leaderfolio/getLeaderPnl'
import {ExchangeType} from '@type/exchange'
import {WSOption} from '@component/select/WizSelectBox'

const portfolio = {
    keyId: ['portfolio'] as const,
    getUserProfile: (id: string, lang?: string) => [...portfolio.keyId, 'userProfile', id, lang] as const,
    getKeyProfile: (id: string) => [...portfolio.keyId, 'keyProfile', id] as const,
    getSidebar: (type: string, id: string) => [...portfolio.keyId, 'sidebar', type, id] as const,
    getUserSidebar: (id: string, locale?: string) => [...portfolio.keyId, 'getUserSidebar', id, locale] as const,
    getKeySidebar: (id: string, locale?: string) => [...portfolio.keyId, 'getKeySidebar', id, locale] as const,
    getDailyPnl: (keyId: string) => [...portfolio.keyId, 'getDailyPnl', keyId] as const,
    getMonthlyPnl: (keyId: string) => [...portfolio.keyId, 'getMonthlyPnl', keyId] as const,
    getKeyHistory: (keyId: string, sort?: HistorySortType, isLeader?: boolean) =>
        [...portfolio.keyId, 'getKeyHistory', keyId, sort, isLeader] as const,
    getCopiableKeys: (exchange: string, leader_key?: string) =>
        [...portfolio.keyId, 'getCopiableKeys', exchange, leader_key] as const,
    getKeyGraph: (keyId: string, duration: GraphPeriodOptionType) =>
        [...portfolio.keyId, 'getKeyGraph', keyId, duration] as const,
    getUserGraph: (keyId: string, duration: DayDuration | GraphPeriodOptionType, permission?: boolean) =>
        [...portfolio.keyId, 'getUserGraph', keyId, duration, permission] as const,
}

const copyfolio = {
    keyId: ['copyfolio'] as const,
    getUserCopyDashboard: (id: string) => [...copyfolio.keyId, 'userCopyDashboard', id] as const,
    getUserCopierMineBot: () => [...copyfolio.keyId, 'getUserCopierMineBot'] as const,
    getUserCopyChart: (id: string, duration: DayDuration) =>
        [...copyfolio.keyId, 'userCopyChart', id, duration] as const,
    getCopierInfo: (id: string) => [...copyfolio.keyId, 'copierInfo', id] as const,
    getCopierDailyPnl: (botId: string) => [...copyfolio.keyId, 'getCopierDailyPnl', botId] as const,
    getCopierMonthlyPnl: (botId: string) => [...copyfolio.keyId, 'getCopierMonthlyPnl', botId] as const,
    getCopierOrderHistory: (key_id: string) => [...copyfolio.keyId, 'getCopierOrderHistory', key_id] as const,
    getLeaderOrderHistory: (key_id: string) => [...copyfolio.keyId, 'getLeaderOrderHistory', key_id] as const,
    getRecommendCopyLeader: () => [...copyfolio.keyId, 'getRecommendCopyLeader'] as const,
    getCopyHistory: (key_id: string) => [...copyfolio.keyId, 'getCopyHistory', key_id] as const,
    getCopyTradingTerms: (locale?: string) => [...copyfolio.keyId, 'getCopyTradingTerms', locale] as const,
}
const leaderfolio = {
    keyId: ['leaderfolio'] as const,
    getUserLeaderDashboard: (id: string) => [...leaderfolio.keyId, 'userLeaderDashboard', id] as const,
    getLeaderMyKeys: () => [...leaderfolio.keyId, 'getLeaderMyKeys'] as const,
    getKeyLeaderDashboard: (id: string) => [...leaderfolio.keyId, 'getKeyLeaderDashboard', id] as const,
    getLeaderEarningGraph: (id: string) => [...leaderfolio.keyId, 'getLeaderEarningGraph', id] as const,
    getLeaderInfo: (key_id: string) => [...leaderfolio.keyId, 'getLeaderInfo', key_id] as const,
    getLeaderJoinableKey: () => [...leaderfolio.keyId, 'getLeaderJoinableKey'] as const,
    getLeaderKeyGraph: (id, duration) => [...leaderfolio.keyId, 'getLeaderJoinableKey', id, duration] as const,
    getLeaderKeysForStartCopy: (id: string) => [...leaderfolio.keyId, 'getLeaderKeysForStartCopy', id] as const,
    getLeaderCopierAll: (id: string) => [...leaderfolio.keyId, 'getLeaderCopierAll', id] as const,
    getLeaderPaymentHistory: (id: string) => [...leaderfolio.keyId, 'getLeaderPaymentHistory', id] as const,
    getLeaderDailyPnl: (keyId: string, when?: LeaderPnlPeriodType) =>
        [...portfolio.keyId, 'getDailyPnl', keyId, when] as const,
    getLeaderHistory: (keyId: string) => [...portfolio.keyId, 'getLeaderHistory', keyId] as const,
    getLeaderDetail: (keyId: string, type: string, when: number) =>
        [...portfolio.keyId, 'getLeaderDetail', keyId, type, when] as const,
    getLeaderDetailAll: (keyId: string, type: string, before, options?: WSOption[]) =>
        [...portfolio.keyId, 'getLeaderDetailAll', keyId, type, before, options] as const,
    getLeaderTableDetail: (keyId: string, type: string, when: number) =>
        [...portfolio.keyId, 'getLeaderTableDetail', keyId, type, when] as const,
}

const exchange = {
    keyId: ['exchange'] as const,
    getList: () => [...exchange.keyId, 'list'] as const,
}

const leaderboard = {
    keyId: ['leaderboard'] as const,
    getLeaguesNotStarted: () => [...leaderboard.keyId, 'getLeaguesNotStarted'] as const,
    getLeagueShow: (locale?: string) => [...leaderboard.keyId, 'getLeagueShow', locale] as const,
    getLeagueShowPrevious: (enabled: boolean = true) =>
        [...leaderboard.keyId, 'getLeagueShowPrevious', enabled] as const,
    getPreviousLeague: () => [...leaderboard.keyId, 'getPreviousLeague'] as const,
    getLeagueDetail: league_id => [...leaderboard.keyId, 'getLeagueDetail', league_id] as const,
    getCopyLeaderRankList: (filterExchange?: string, filterPeriod?: RankPeriod) =>
        [...leaderboard.keyId, 'getCopyLeaderRankList', filterExchange, filterPeriod] as const,
    getBanners: (locale?: string) => [...leaderboard.keyId, 'getBanners', locale] as const,
    getFavoriteLeaders: (userId: string, isFavorite: boolean, exchange?: string) =>
        [...leaderboard.keyId, 'getFavoriteLeaders', userId, isFavorite, exchange] as const,
    getRank: (
        exchange?: ExchangeType | string,
        period?: RankPeriod,
        rankKind?: RankKind,
        sort?: RankSortBy,
        id?: string,
        locale?: string,
    ) => [...leaderboard.keyId, 'getRank', exchange, period, rankKind, sort, id, locale] as const,
    getMyRank: (id?: string) => [...leaderboard.keyId, 'getMyRank', id] as const,
    getRankLeague: (league_id?: string, rankKind?: RankKind, id?: string, locale?: string) =>
        [...leaderboard.keyId, 'getRankLeague', league_id, rankKind, id, locale] as const,
    getUserMini: (id: string) => [...leaderboard.keyId, 'getUserMini', id] as const,
    getLeaderMini: (id: string) => [...leaderboard.keyId, 'getLeaderMini', id] as const,
}

const currency = {
    keyId: ['currency'] as const,
    getList: () => [...currency.keyId, 'list'] as const,
}

const invoice = {
    keyId: ['invoice'] as const,
    getInvoiceList: (id: string) => [...invoice.keyId, 'getInvoiceList', id] as const,
    getInvoiceKey: (id: string) => [...invoice.keyId, 'getInvoiceKey', id] as const,
    getGetKeyInvoice: (id: string) => [...invoice.keyId, 'getGetKeyInvoice', id] as const,
    getPendingInvoiceList: (id?: string) => [...invoice.keyId, 'getPendingInvoiceList', id] as const,
}

const account = {
    keyId: ['account'] as const,
    getMe: (userId?: string) => [...account.keyId, 'getMe', userId] as const,
    getApi: () => [...account.keyId, 'getApi'] as const,
    getDeleteKeyCheck: (keyId?: string) => [...account.keyId, 'getDeleteKeyCheck', keyId] as const,
}

const wallet = {
    keyId: ['wallet'] as const,
    getWithdrawHistory: id => [...wallet.keyId, 'getWithdrawHistory', id] as const,
    getTransaction: (id, action) => [...wallet.keyId, 'getTransaction', id, action] as const,
    getDepositAddress: (kind, chain) => [...wallet.keyId, 'getDepositAddress', kind, chain] as const,
    getDepositAddresses: () => [...wallet.keyId, 'getDepositAddresses'] as const,
    getDepositCurrencies: () => [...wallet.keyId, 'getDepositCurrencies'] as const,
    getUserWallets: () => [...wallet.keyId, 'getUserWallets'] as const,
    getCryptoWithdrawFee: tokenSymbol => [...wallet.keyId, 'getCryptoWithdrawFee', tokenSymbol] as const,
    getGetCryptoWithdrawConfirmDetail: confirmKey =>
        [...wallet.keyId, 'getGetCryptoWithdrawConfirmDetail', confirmKey] as const,
}

const notification = {
    keyId: ['notification'] as const,
    getNotificationCount: (id?: string) => [...notification.keyId, 'getNotificationCount', id] as const,
    getPreferences: () => [...notification.keyId, 'getPreferences'] as const,
    getTradingsList: (user_id?: string) => [...notification.keyId, 'getTradingsList', user_id] as const,
    getNotificationsList: (id?: string) => [...notification.keyId, 'getNotificationsList', id] as const,
    postUserPreferences: () => [...notification.keyId, 'postUserPreferences'] as const,
}

const landing = {
    keyId: ['landing'] as const,
    getLanding: () => [...landing.keyId, 'getLanding'] as const,
}

const pro = {
    keyId: ['pro'] as const,
    getSubscriptionHistory: (id: string) => [...pro.keyId, 'getSubscriptionHistory', id] as const,
    getProBoot: () => [...pro.keyId, 'getProBoot'] as const,
    getProPayConfirmStatus: () => [...pro.keyId, 'getProPayConfirmStatus'] as const,
    getProPayInfo: (id: string) => [...pro.keyId, 'getProPayInfo', id] as const,
}

const subscribers = {
    keyId: ['subscribers'] as const,
    getSubscribers: user_id => [...subscribers.keyId, 'getSubscribers', user_id] as const,
    getSubscribings: user_id => [...subscribers.keyId, 'getSubscribings', user_id] as const,
}

const post = {
    keyId: ['post'] as const,
    getAnnouncements: (category?: AnnounceType, currentPage?: number, locale?: string) =>
        [...post.keyId, 'getAnnouncements', category, currentPage, locale] as const,
    getAnnounceEvents: (active?: boolean, open?: boolean) =>
        [...post.keyId, 'getAnnounceEvents', active, open] as const,
    getTrending: (currentPage?: number, locale?: string) =>
        [...post.keyId, 'getTrending', currentPage, locale] as const,
}

const report = {
    keyId: ['report'] as const,
    getDailyReport: (id, userId, lang) => [...report.keyId, 'getDailyReport', id, userId, lang] as const,
    getTodayDailyReport: (id, lang) => [...report.keyId, 'getTodayDailyReport', id, lang] as const,
}

const bot = {
    keyId: ['bot'] as const,
    getMyBots: (botType?: BotType, id?: string) => [...bot.keyId, 'getMyBots', botType, id] as const,
}

const point = {
    keyId: ['point'] as const,
    getPoint: (user_id?: string, locale: string = 'en') => [...point.keyId, 'getPoint', user_id, locale] as const,
    getPointDetail: (user_id?: string, locale: string = 'en') =>
        [...point.keyId, 'getPointDetail', user_id, locale] as const,
    postPoint: () => [...point.keyId, 'postPoint'] as const,
}
const bookmark = {
    keyId: ['bookmark'] as const,
    getBookmark: (id: string) => [...bookmark.keyId, 'getBookmark', id] as const,
    getBookmarkList: (id: string) => [...bookmark.keyId, 'getBookmarkList', id] as const,
}

const nats = {
    keyId: ['nats'] as const,
    getUserInfo: (userId?: string) => [...nats.keyId, 'getUserInfo', userId] as const,
    getChartIndicator: () => [...nats.keyId, 'getChartIndicator'] as const,
    getJetstreamChat: (subject: string, id: string) => [...nats.keyId, 'getJetstreamChat', subject, id] as const,
}

const socket = {
    keyId: ['socket'] as const,
    getBinanceKline: () => [...socket.keyId, 'getBinanceKline'] as const,
}

const market = {
    keyId: ['market'] as const,
    getExchangeTokens: (exchange?: string) => [...market.keyId, 'getExchangeTokens', exchange] as const,
}

const ban = {
    keyId: ['ban'] as const,
    getIsBanned: () => [...ban.keyId, 'getIsBanned'] as const,
}

const ai = {
    keyId: ['ai'] as const,
    getAiAnalysis: id => [...ai.keyId, 'getAiAnalysis', id] as const,
}

export const QueryCacheKeys = {
    portfolio,
    copyfolio,
    leaderfolio,
    exchange,
    currency,
    invoice,
    account,
    leaderboard,
    notification,
    wallet,
    landing,
    pro,
    subscribers,
    post,
    report,
    bot,
    point,
    bookmark,
    nats,
    socket,
    market,
    ban,
    ai,
}
