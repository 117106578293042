import React, {Fragment} from 'react'
import Link from 'next/link'
import Span from '@component/Text/Span'

const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

const useText = () => {
    const renderHyperlink = (contents: string) => {
        let url = contents?.match(regex)?.[0]
        if (url) {
            const splitContents = contents.split(url)

            return splitContents.map((value, i) => {
                if (i === 0) {
                    return (
                        <Fragment key={`${value}_${i}`}>
                            {value}
                            <Link href={url} target={'_blank'}>
                                <Span
                                    className={
                                        'underline break-all hover:text-primary_shade dark:hover:text-dark_primary_shade active:text-primary dark:active:text-dark_primary'
                                    }>
                                    {url}
                                </Span>
                            </Link>
                        </Fragment>
                    )
                }
                return renderHyperlink(value)
            })
        }
        return contents
    }

    return {renderHyperlink}
}

export default useText
