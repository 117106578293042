import {useEffect} from 'react'
import {isNotEmpty} from '@util/strings'
import {amplitudeConfig} from '@constant/config'
import * as amplitude from '@amplitude/analytics-browser'

export interface ILogProps {
    pageName?: string
}

const useScreenLog = (params: ILogProps = {}) => {
    const {pageName} = params

    useEffect(() => {
        if (isNotEmpty(pageName)) event(`[SCREEN] ${pageName}`)
    }, [pageName])

    const event = (eventName: string) => {
        if (amplitudeConfig.isSupportLog === true) {
            amplitude?.track(eventName)
        }
    }

    return {event}
}

export default useScreenLog
